.social-link-list {
  --link-size: 3rem;
  --gap: 1.25rem;

  display: flex;
  flex-wrap: wrap;
  gap: var(--gap);
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.social-link-list > li > a {
  --transition: ease-in-out var(--transition-duration-arrow);

  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: var(--link-size);
  height: var(--link-size);
  color: hsl(var(--color-blue-500));
  background-color: hsl(var(--color-blue-100));
  border-radius: var(--br-max);
  line-height: 0;
  transition: background-color var(--transition), color var(--transition);
}

.social-link-list > li > a:hover {
  background-color: hsl(var(--color-blue-500));
  color: hsl(var(--background-color));
}
