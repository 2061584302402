:where(.button) {
  --bg-opacity: 1;

  --bg-color: var(--color-blue-500);
  --bg-color-hover: var(--color-blue-700);
  --bg-color-inverted: var(--color-blue-100);
  --bg-color-inverted-hover: var(--color-blue-200);

  --fg-color: var(--background-color);
  --fg-color-hover: var(--background-color);
  --fg-color-inverted: var(--color-blue-600);
  --fg-color-inverted-hover: var(--color-blue-900);

  --shadow-color-inverted: var(--color-blue-400);

  --transition: ease-in var(--transition-duration-bolt);

  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.875em 1.25em;
  border-radius: 0.5em;
  border: 0;
  outline: none;
  color: hsl(var(--fg-color));
  font-weight: 600;
  font-size: 0.875rem;
  gap: 0.5rem;
  line-height: 1.5;
  cursor: pointer;
  transform: translateZ(0);
  background-color: hsl(var(--bg-color) / var(--bg-opacity));
  transition: background-color var(--transition), color var(--transition),
    filter var(--transition);
}

:where(.button:not([data-variant="transparent"]):hover) {
  --shadow-color: var(--color-black);
  --shadow-opacity: 0.1;

  color: hsl(var(--fg-color-hover));
  background-color: hsl(var(--bg-color-hover) / var(--bg-opacity));
  filter: drop-shadow(
    0 0.5em 0.75em hsl(var(--shadow-color) / var(--shadow-opacity))
  );
}

:where(.button[data-variant="inverted"]) {
  color: hsl(var(--fg-color-inverted));
  background-color: hsl(var(--bg-color-inverted) / var(--bg-opacity));
}

:where(.button[data-variant="inverted"]:hover) {
  --shadow-color: var(--shadow-color-inverted);
  --shadow-opacity: 0.3;

  color: hsl(var(--fg-color-inverted-hover));
  background-color: hsl(var(--bg-color-inverted-hover) / var(--bg-opacity));
}

:where(.button[data-variant="transparent"]) {
  --bg-opacity: 0;
  --fg-color: var(--color-blue-500);
  --fg-color-hover: var(--color-blue-800);

  padding: 0.5em 0;
}

:where(.button[data-variant="transparent"]:hover) {
  text-decoration: underline;
}

:where(.button[data-icon-position="after"]) {
  flex-direction: row-reverse;
}
