@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;900&display=swap");

@layer reset {
  /* Box sizing rules */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  /* Remove default margin */
  body,
  h1,
  h2,
  h3,
  h4,
  p,
  figure,
  blockquote,
  dl,
  dd {
    margin: 0;
    padding: 0;
  }

  /* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
  ul[role="list"],
  ol[role="list"] {
    list-style: none;
  }

  /* Set core root defaults */
  html:focus-within {
    scroll-behavior: smooth;
  }

  /* Set core body defaults */
  body {
    min-height: 100vh;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
  }

  /* A elements that don't have a class get default styles */
  a:not([class]) {
    -webkit-text-decoration-skip: ink;
            text-decoration-skip-ink: auto;
  }

  /* Make images easier to work with */
  img,
  picture {
    max-width: 100%;
    display: block;
  }

  /* Inherit fonts for inputs and buttons */
  input,
  button,
  textarea,
  select {
    font: inherit;
  }

  /* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
  @media (prefers-reduced-motion: reduce) {
    html:focus-within {
      scroll-behavior: auto;
    }

    *,
    *::before,
    *::after {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }
}

/* For debugging... */
/* *,
*::after,
*::before {
  outline: 1px solid red;
} */

:root {
  /**
   * HSL color components.
   * Usage: hsl(var(--color-name));
   * 
   * Advantage to specifying components only is that you can easily adjust the
   * opacity of the color:
   * hsl(var(--color-name) / 0.5);
   */
  --color-white-hs: 0 0%;
  --color-white-l: 100%;

  --color-black-hs: 0 0%;
  --color-black-l: 0%;

  --color-eerie-black-hs: 0 0%;
  --color-eerie-black-l: 12%;

  --color-blue-hs: 205 100%;
  --color-blue-100-l: 94%;
  --color-blue-200-l: 75%;
  --color-blue-300-l: 60%;
  --color-blue-400-l: 50%;
  --color-blue-500-l: 40%;
  --color-blue-600-l: 30%;
  --color-blue-700-l: 25%;
  --color-blue-800-l: 20%;
  --color-blue-900-l: 15%;

  --color-gray-hs: 0 0%;
  --color-gray-100-l: 92%;
  --color-gray-200-l: 87%;
  --color-gray-300-l: 77%;
  --color-gray-400-l: 67%;
  --color-gray-500-l: 57%;
  --color-gray-600-l: 47%;
  --color-gray-700-l: 37%;
  --color-gray-800-l: 27%;
  --color-gray-900-l: 17%;
  --color-gray-950-l: 8%;

  --color-pink-hs: 301 94%;
  --color-pink-100-l: 92%;
  --color-pink-200-l: 87%;
  --color-pink-300-l: 77%;
  --color-pink-400-l: 67%;
  --color-pink-500-l: 57%;
  --color-pink-600-l: 47%;
  --color-pink-700-l: 37%;
  --color-pink-800-l: 27%;
  --color-pink-900-l: 17%;

  --color-purple-hs: 223 25%;
  --color-purple-100-l: 92%;
  --color-purple-200-l: 86%;
  --color-purple-300-l: 79%;
  --color-purple-400-l: 69%;
  --color-purple-500-l: 59%;
  --color-purple-600-l: 49%;
  --color-purple-700-l: 39%;
  --color-purple-800-l: 29%;
  --color-purple-900-l: 19%;

  --color-chinese-black-hs: 0 0%;
  --color-chinese-black-100-l: 90%;
  --color-chinese-black-200-l: 77%;
  --color-chinese-black-300-l: 67%;
  --color-chinese-black-400-l: 57%;
  --color-chinese-black-500-l: 47%;
  --color-chinese-black-600-l: 37%;
  --color-chinese-black-700-l: 27%;
  --color-chinese-black-800-l: 17%;
  --color-chinese-black-900-l: 7%;

  --color-dark-gunmetal-hs: 215 23%;
  --color-dark-gunmetal-100-l: 94%;
  --color-dark-gunmetal-200-l: 84%;
  --color-dark-gunmetal-300-l: 74%;
  --color-dark-gunmetal-400-l: 64%;
  --color-dark-gunmetal-500-l: 54%;
  --color-dark-gunmetal-600-l: 44%;
  --color-dark-gunmetal-700-l: 34%;
  --color-dark-gunmetal-800-l: 24%;
  --color-dark-gunmetal-900-l: 14%;

  --color-blue-violet-hs: 243 39%;
  --color-blue-violet-100-l: 97%;
  --color-blue-violet-200-l: 87%;
  --color-blue-violet-300-l: 77%;
  --color-blue-violet-400-l: 67%;
  --color-blue-violet-500-l: 57%;
  --color-blue-violet-600-l: 47%;
  --color-blue-violet-700-l: 37%;
  --color-blue-violet-800-l: 27%;
  --color-blue-violet-900-l: 17%;

  --color-tomato-red-hs: 9 100%;
  --color-tomato-red-100-l: 94%;
  --color-tomato-red-200-l: 84%;
  --color-tomato-red-300-l: 74%;
  --color-tomato-red-400-l: 64%;
  --color-tomato-red-500-l: 54%;
  --color-tomato-red-600-l: 44%;
  --color-tomato-red-700-l: 34%;
  --color-tomato-red-800-l: 24%;
  --color-tomato-red-900-l: 14%;

  --color-sky-blue: hsl(186, 70%, 74%);
  --color-mauve: hsl(276, 94%, 82%);
  --color-congo-pink: hsl(9, 97%, 74%);
  --color-shandy: hsl(49, 100%, 73%);
  --color-heliotrope: hsl(276, 93%, 68%);
  --color-charged-blue: hsl(187, 71%, 50%);
  --color-orange-soda: hsl(9, 97%, 61%);
  --color-magnolia: hsl(278, 92%, 95%);
  --color-misty-rose: hsl(8, 100%, 94%);
  --color-bubbles: hsl(185, 70%, 94%);
  --color-cornsilk: hsl(49, 100%, 93%);
  --color-cyber-yellow: hsl(49, 100%, 50%);

  --br-100: 0.125rem;
  --br-200: 0.25rem;
  --br-300: 0.375rem;
  --br-400: 0.5rem;
  --br-500: 0.625rem;
  --br-600: 0.75rem;
  --br-700: 1rem;
  --br-800: 1.25rem;
  --br-900: 1.5rem;
  --br-max: 100vw;

  --header-height: 4rem;

  --transition-duration-snail: 1s;
  --transition-duration-sloth: 750ms;
  --transition-duration-turtle: 500ms;
  --transition-duration-normal: 350ms;
  --transition-duration-arrow: 250ms;
  --transition-duration-bolt: 150ms;
  --transition-duration-bullet: 100ms;
  --transition-duration-lightning: 50ms;

  /* Content centering */
  --content-max-width: 75rem;
  --content-inline-padding: max(
    calc((100vw - var(--content-max-width)) / 2),
    0.75rem
  );

  --aspect-article-cover: 7 / 4;
  --aspect-project-cover: 16 / 9;
}

* {
  box-sizing: border-box;
}

@layer base {
  html,
  body {
    --color-white: var(--color-white-hs) var(--color-white-l);
    --color-black: var(--color-black-hs) var(--color-black-l);
    --color-eerie-black: var(--color-eerie-black-hs) var(--color-eerie-black-l);

    --background-color: var(--color-white);
    --foreground-color: var(--color-chinese-black-800);

    --color-blue-100: var(--color-blue-hs) var(--color-blue-100-l);
    --color-blue-200: var(--color-blue-hs) var(--color-blue-200-l);
    --color-blue-300: var(--color-blue-hs) var(--color-blue-300-l);
    --color-blue-400: var(--color-blue-hs) var(--color-blue-400-l);
    --color-blue-500: var(--color-blue-hs) var(--color-blue-500-l);
    --color-blue-600: var(--color-blue-hs) var(--color-blue-600-l);
    --color-blue-700: var(--color-blue-hs) var(--color-blue-700-l);
    --color-blue-800: var(--color-blue-hs) var(--color-blue-800-l);
    --color-blue-900: var(--color-blue-hs) var(--color-blue-900-l);

    --color-gray-100: var(--color-gray-hs) var(--color-gray-100-l);
    --color-gray-200: var(--color-gray-hs) var(--color-gray-200-l);
    --color-gray-300: var(--color-gray-hs) var(--color-gray-300-l);
    --color-gray-400: var(--color-gray-hs) var(--color-gray-400-l);
    --color-gray-500: var(--color-gray-hs) var(--color-gray-500-l);
    --color-gray-600: var(--color-gray-hs) var(--color-gray-600-l);
    --color-gray-700: var(--color-gray-hs) var(--color-gray-700-l);
    --color-gray-800: var(--color-gray-hs) var(--color-gray-800-l);
    --color-gray-900: var(--color-gray-hs) var(--color-gray-900-l);
    --color-gray-950: var(--color-gray-hs) var(--color-gray-950-l);

    --color-pink-100: var(--color-pink-hs) var(--color-pink-100-l);
    --color-pink-200: var(--color-pink-hs) var(--color-pink-200-l);
    --color-pink-300: var(--color-pink-hs) var(--color-pink-300-l);
    --color-pink-400: var(--color-pink-hs) var(--color-pink-400-l);
    --color-pink-500: var(--color-pink-hs) var(--color-pink-500-l);
    --color-pink-600: var(--color-pink-hs) var(--color-pink-600-l);
    --color-pink-700: var(--color-pink-hs) var(--color-pink-700-l);
    --color-pink-800: var(--color-pink-hs) var(--color-pink-800-l);
    --color-pink-900: var(--color-pink-hs) var(--color-pink-900-l);

    --color-purple-100: var(--color-purple-hs) var(--color-purple-100-l);
    --color-purple-200: var(--color-purple-hs) var(--color-purple-200-l);
    --color-purple-300: var(--color-purple-hs) var(--color-purple-300-l);
    --color-purple-400: var(--color-purple-hs) var(--color-purple-400-l);
    --color-purple-500: var(--color-purple-hs) var(--color-purple-500-l);
    --color-purple-600: var(--color-purple-hs) var(--color-purple-600-l);
    --color-purple-700: var(--color-purple-hs) var(--color-purple-700-l);
    --color-purple-800: var(--color-purple-hs) var(--color-purple-800-l);
    --color-purple-900: var(--color-purple-hs) var(--color-purple-900-l);

    --color-chinese-black-100: var(--color-chinese-black-hs)
      var(--color-chinese-black-100-l);
    --color-chinese-black-200: var(--color-chinese-black-hs)
      var(--color-chinese-black-200-l);
    --color-chinese-black-300: var(--color-chinese-black-hs)
      var(--color-chinese-black-300-l);
    --color-chinese-black-400: var(--color-chinese-black-hs)
      var(--color-chinese-black-400-l);
    --color-chinese-black-500: var(--color-chinese-black-hs)
      var(--color-chinese-black-500-l);
    --color-chinese-black-600: var(--color-chinese-black-hs)
      var(--color-chinese-black-600-l);
    --color-chinese-black-700: var(--color-chinese-black-hs)
      var(--color-chinese-black-700-l);
    --color-chinese-black-800: var(--color-chinese-black-hs)
      var(--color-chinese-black-800-l);
    --color-chinese-black-900: var(--color-chinese-black-hs)
      var(--color-chinese-black-900-l);

    --color-dark-gunmetal-100: var(--color-dark-gunmetal-hs)
      var(--color-dark-gunmetal-100-l);
    --color-dark-gunmetal-200: var(--color-dark-gunmetal-hs)
      var(--color-dark-gunmetal-200-l);
    --color-dark-gunmetal-300: var(--color-dark-gunmetal-hs)
      var(--color-dark-gunmetal-300-l);
    --color-dark-gunmetal-400: var(--color-dark-gunmetal-hs)
      var(--color-dark-gunmetal-400-l);
    --color-dark-gunmetal-500: var(--color-dark-gunmetal-hs)
      var(--color-dark-gunmetal-500-l);
    --color-dark-gunmetal-600: var(--color-dark-gunmetal-hs)
      var(--color-dark-gunmetal-600-l);
    --color-dark-gunmetal-700: var(--color-dark-gunmetal-hs)
      var(--color-dark-gunmetal-700-l);
    --color-dark-gunmetal-800: var(--color-dark-gunmetal-hs)
      var(--color-dark-gunmetal-800-l);
    --color-dark-gunmetal-900: var(--color-dark-gunmetal-hs)
      var(--color-dark-gunmetal-900-l);

    --color-blue-violet-100: var(--color-blue-violet-hs)
      var(--color-blue-violet-100-l);
    --color-blue-violet-200: var(--color-blue-violet-hs)
      var(--color-blue-violet-200-l);
    --color-blue-violet-300: var(--color-blue-violet-hs)
      var(--color-blue-violet-300-l);
    --color-blue-violet-400: var(--color-blue-violet-hs)
      var(--color-blue-violet-400-l);
    --color-blue-violet-500: var(--color-blue-violet-hs)
      var(--color-blue-violet-500-l);
    --color-blue-violet-600: var(--color-blue-violet-hs)
      var(--color-blue-violet-600-l);
    --color-blue-violet-700: var(--color-blue-violet-hs)
      var(--color-blue-violet-700-l);
    --color-blue-violet-800: var(--color-blue-violet-hs)
      var(--color-blue-violet-800-l);
    --color-blue-violet-900: var(--color-blue-violet-hs)
      var(--color-blue-violet-900-l);

    --color-tomato-red-100: var(--color-tomato-red-hs)
      var(--color-tomato-red-100-l);
    --color-tomato-red-200: var(--color-tomato-red-hs)
      var(--color-tomato-red-200-l);
    --color-tomato-red-300: var(--color-tomato-red-hs)
      var(--color-tomato-red-300-l);
    --color-tomato-red-400: var(--color-tomato-red-hs)
      var(--color-tomato-red-400-l);
    --color-tomato-red-500: var(--color-tomato-red-hs)
      var(--color-tomato-red-500-l);
    --color-tomato-red-600: var(--color-tomato-red-hs)
      var(--color-tomato-red-600-l);
    --color-tomato-red-700: var(--color-tomato-red-hs)
      var(--color-tomato-red-700-l);
    --color-tomato-red-800: var(--color-tomato-red-hs)
      var(--color-tomato-red-800-l);
    --color-tomato-red-900: var(--color-tomato-red-hs)
      var(--color-tomato-red-900-l);

    color-scheme: light dark;
    padding: 0;
    margin: 0;
    font-family: Inter, sans-serif;
    background-color: hsl(var(--background-color));
    color: hsl(var(--foreground-color));
  }

  body[data-no-scroll] {
    overflow: hidden;
  }

  a {
    text-decoration: none;
  }

  hr {
    display: block;
    width: 100%;
    height: 2px;
    border: 0;
    border-top: 1px dashed hsl(var(--color-gray-200));
  }

  address {
    font-style: normal;
  }

  button {
    display: inline-flex;
    padding: 0;
    margin: 0;
    border: 0;
    background-color: transparent;
    color: inherit;
  }

  h1,
  [data-as="h1"] {
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.5rem;
  }

  h2,
  [data-as="h2"] {
    font-size: 1.75rem;
    font-weight: 700;
    line-height: 2.125rem;
  }

  h3,
  [data-as="h3"] {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.625rem;
  }

  h4,
  [data-as="h4"] {
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.5rem;
  }

  h5,
  [data-as="h5"] {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.375rem;
  }

  h6,
  [data-as="h6"] {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.25rem;
  }

  .prose {
    line-height: 1.75rem;
  }

  article,
  p:not([class]) {
    line-height: 1.75rem;
  }

  .label {
    font-size: 1rem;
    font-weight: 700;
  }

  .rich-content {
    white-space: pre-line;
  }

  .info-cell {
    display: grid;
    grid-gap: 0.25rem;
    gap: 0.25rem;
    align-content: flex-start;
    padding: 0.75rem 0 1rem;
    border-top-width: 2px;
    border-bottom-width: 0;
    border-left-width: 0px;
    border-right-width: 0px;
    border-style: solid;
    border-image: linear-gradient(
        to right,
        hsl(var(--color-blue-400)),
        hsl(var(--color-purple-400))
      )
      1;
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  .shadow {
    --offset: 0.5em;
    --blur: 0.75em;
    --color: 0 0% 0%;
    --opacity: 0.16;
    --transition-duration: var(--transition-duration-arrow);

    filter: drop-shadow(
      0 var(--offset) var(--blur) hsl(var(--color) / var(--opacity))
    );

    transform: translateZ(0);
    transition: filter ease-in-out var(--transition-duration);
  }

  :is(.shadow:hover, .shadow:focus) {
    --offset: 1.5em;
    --blur: 3em;
  }

  .slick-list :is(.shadow:hover, .shadow:focus) {
    --offset: 0.75em;
    --blur: 0.75em;
  }

  @media screen and (min-width: 48rem) {
    :root {
      --header-height: 5rem;
    }

    h1,
    [data-as="h1"] {
      font-size: 3.5rem;
      line-height: 4rem;
    }

    h2,
    [data-as="h2"] {
      font-size: 2.75rem;
      line-height: 3.5rem;
    }

    h3,
    [data-as="h3"] {
      font-size: 2rem;
      line-height: 2.5rem;
    }

    h4,
    [data-as="h4"] {
      font-size: 1.5rem;
      line-height: 2rem;
    }

    h5,
    [data-as="h5"] {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }

    h6,
    [data-as="h6"] {
      font-size: 1.125rem;
      line-height: 1.375rem;
    }
  }

  @media screen and (min-width: 48rem) {
    .prose {
      max-width: 86ch;
    }
  }

  body[data-color-scheme-dark] {
    --background-color: var(--color-chinese-black-100);
    --foreground-color: var(--color-gray-950);

    --color-white-l: 13%;
    --color-black-l: 87%;

    --color-blue-100-l: 12%;
    --color-blue-200-l: 30%;
    --color-blue-300-l: 40%;
    --color-blue-400-l: 45%;
    --color-blue-500-l: 60%;
    --color-blue-600-l: 70%;
    --color-blue-700-l: 75%;
    --color-blue-800-l: 80%;
    --color-blue-900-l: 85%;

    --color-gray-100-l: 16%;
    --color-gray-200-l: 26%;
    --color-gray-300-l: 33%;
    --color-gray-400-l: 60%;
    --color-gray-500-l: 63%;
    --color-gray-600-l: 68%;
    --color-gray-700-l: 70%;
    --color-gray-800-l: 73%;
    --color-gray-900-l: 83%;
    --color-gray-950-l: 92%;

    --color-pink-100-l: 16%;
    --color-pink-200-l: 23%;
    --color-pink-300-l: 33%;
    --color-pink-400-l: 43%;
    --color-pink-500-l: 50%;
    --color-pink-600-l: 53%;
    --color-pink-700-l: 63%;
    --color-pink-800-l: 73%;
    --color-pink-900-l: 83%;

    --color-purple-100-l: 16%;
    --color-purple-200-l: 30%;
    --color-purple-300-l: 36%;
    --color-purple-400-l: 42%;
    --color-purple-500-l: 45%;
    --color-purple-600-l: 60%;
    --color-purple-700-l: 61%;
    --color-purple-800-l: 71%;
    --color-purple-900-l: 81%;

    --color-chinese-black-100-l: 10%;
    --color-chinese-black-200-l: 32%;
    --color-chinese-black-300-l: 38%;
    --color-chinese-black-400-l: 42%;
    --color-chinese-black-500-l: 53%;
    --color-chinese-black-600-l: 63%;
    --color-chinese-black-700-l: 73%;
    --color-chinese-black-800-l: 78%;
    --color-chinese-black-900-l: 83%;

    --color-dark-gunmetal-100-l: 12%;
    --color-dark-gunmetal-200-l: 26%;
    --color-dark-gunmetal-300-l: 36%;
    --color-dark-gunmetal-400-l: 46%;
    --color-dark-gunmetal-500-l: 54%;
    --color-dark-gunmetal-600-l: 56%;
    --color-dark-gunmetal-700-l: 66%;
    --color-dark-gunmetal-800-l: 76%;
    --color-dark-gunmetal-900-l: 86%;

    --color-blue-violet-100-l: 10%;
    --color-blue-violet-200-l: 13%;
    --color-blue-violet-300-l: 23%;
    --color-blue-violet-400-l: 46%;
    --color-blue-violet-500-l: 70%;
    --color-blue-violet-600-l: 53%;
    --color-blue-violet-700-l: 63%;
    --color-blue-violet-800-l: 73%;
    --color-blue-violet-900-l: 83%;

    --color-tomato-red-100-l: 12%;
    --color-tomato-red-200-l: 20%;
    --color-tomato-red-300-l: 26%;
    --color-tomato-red-400-l: 36%;
    --color-tomato-red-500-l: 54%;
    --color-tomato-red-600-l: 56%;
    --color-tomato-red-700-l: 66%;
    --color-tomato-red-800-l: 76%;
    --color-tomato-red-900-l: 86%;

    --color-sky-blue: hsl(186, 70%, 32%);
    --color-mauve: hsl(276, 94%, 82%);
    --color-congo-pink: hsl(9, 97%, 74%);
    --color-shandy: hsl(49, 100%, 27%);
    --color-heliotrope: hsl(276, 93%, 68%);
    --color-charged-blue: hsl(187, 71%, 50%);
    --color-orange-soda: hsl(9, 97%, 61%);
    --color-magnolia: hsl(278, 92%, 10%);
    --color-misty-rose: hsl(8, 100%, 12%);
    --color-bubbles: hsl(185, 70%, 12%);
    --color-cornsilk: hsl(49, 100%, 14%);
    --color-cyber-yellow: hsl(49, 100%, 40%);

    position: relative;
  }

  body[data-color-scheme-dark]::before {
    content: "";
    position: absolute;
    top: calc(var(--header-height) * -1);
    left: 0;
    right: 0;
    height: var(--header-height);
    background-color: hsl(var(--color-chinese-black-900));
  }
}

@layer utils {
  [data-line-clamp] {
    --line-clamp: 1;

    display: -webkit-box;
    -webkit-line-clamp: var(--line-clamp);
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  [data-line-clamp="2"] {
    --line-clamp: 2;
  }

  [data-line-clamp="3"] {
    --line-clamp: 3;
  }

  [data-line-clamp="4"] {
    --line-clamp: 4;
  }

  [data-line-clamp="5"] {
    --line-clamp: 5;
  }

  [data-line-clamp="6"] {
    --line-clamp: 6;
  }

  :where([data-inherit-color], .inherit-color) {
    color: inherit;
  }

  .external-link {
    color: hsl(var(--color-blue-600));
  }

  .external-link:hover {
    text-decoration: underline;
  }

  .internal-link:hover {
    color: hsl(var(--color-blue-500));
  }
}

body {
  --toastify-color-light: hsl(var(--background-color) / 70%);
  --toastify-text-color-light: hsl(var(--foreground-color));
}

.Toastify__toast {
  -webkit-backdrop-filter: blur(1rem);
          backdrop-filter: blur(1rem);
  box-shadow: 0 0.25rem 0.5rem hsl(var(--foreground-color) / 12%) !important;
  border-radius: var(--br-400) !important;
}

.Toastify__close-button--light {
  color: hsl(var(--foreground-color)) !important;
}

:where(.Button_button__L2wUb) {
  --bg-opacity: 1;

  --bg-color: var(--color-blue-500);
  --bg-color-hover: var(--color-blue-700);
  --bg-color-inverted: var(--color-blue-100);
  --bg-color-inverted-hover: var(--color-blue-200);

  --fg-color: var(--background-color);
  --fg-color-hover: var(--background-color);
  --fg-color-inverted: var(--color-blue-600);
  --fg-color-inverted-hover: var(--color-blue-900);

  --shadow-color-inverted: var(--color-blue-400);

  --transition: ease-in var(--transition-duration-bolt);

  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.875em 1.25em;
  border-radius: 0.5em;
  border: 0;
  outline: none;
  color: hsl(var(--fg-color));
  font-weight: 600;
  font-size: 0.875rem;
  gap: 0.5rem;
  line-height: 1.5;
  cursor: pointer;
  transform: translateZ(0);
  background-color: hsl(var(--bg-color) / var(--bg-opacity));
  transition: background-color var(--transition), color var(--transition),
    filter var(--transition);
}

:where(.Button_button__L2wUb:not([data-variant="transparent"]):hover) {
  --shadow-color: var(--color-black);
  --shadow-opacity: 0.1;

  color: hsl(var(--fg-color-hover));
  background-color: hsl(var(--bg-color-hover) / var(--bg-opacity));
  filter: drop-shadow(
    0 0.5em 0.75em hsl(var(--shadow-color) / var(--shadow-opacity))
  );
}

:where(.Button_button__L2wUb[data-variant="inverted"]) {
  color: hsl(var(--fg-color-inverted));
  background-color: hsl(var(--bg-color-inverted) / var(--bg-opacity));
}

:where(.Button_button__L2wUb[data-variant="inverted"]:hover) {
  --shadow-color: var(--shadow-color-inverted);
  --shadow-opacity: 0.3;

  color: hsl(var(--fg-color-inverted-hover));
  background-color: hsl(var(--bg-color-inverted-hover) / var(--bg-opacity));
}

:where(.Button_button__L2wUb[data-variant="transparent"]) {
  --bg-opacity: 0;
  --fg-color: var(--color-blue-500);
  --fg-color-hover: var(--color-blue-800);

  padding: 0.5em 0;
}

:where(.Button_button__L2wUb[data-variant="transparent"]:hover) {
  text-decoration: underline;
}

:where(.Button_button__L2wUb[data-icon-position="after"]) {
  flex-direction: row-reverse;
}

.Contacts_contacts__UmhgK {
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
}

.Contacts_contacts-title__NGm7q {
  font-weight: 700;
}

.Contacts_contacts-address__Qa7N8 {
  display: grid;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  padding: 0;
  margin: 0;
}

.Socials_social-link-list__wdhFU {
  --link-size: 3rem;
  --gap: 1.25rem;

  display: flex;
  flex-wrap: wrap;
  gap: var(--gap);
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.Socials_social-link-list__wdhFU > li > a {
  --transition: ease-in-out var(--transition-duration-arrow);

  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: var(--link-size);
  height: var(--link-size);
  color: hsl(var(--color-blue-500));
  background-color: hsl(var(--color-blue-100));
  border-radius: var(--br-max);
  line-height: 0;
  transition: background-color var(--transition), color var(--transition);
}

.Socials_social-link-list__wdhFU > li > a:hover {
  background-color: hsl(var(--color-blue-500));
  color: hsl(var(--background-color));
}

.Header_header__D4RXM {
  --transition: var(--transition-duration-bullet) ease-in;

  display: grid;
  grid-template-columns: 2rem 1fr 2rem;
  grid-template-rows: var(--header-height) calc(100vh - var(--header-height));
  place-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  height: var(--header-height);
  padding-inline: 0.75rem;
  background-color: hsl(
    var(--background-color)
  ); /* 86% opacity for blur effect */
  /* backdrop-filter: blur(1.5rem); */
  z-index: 10;
  transition: background-color var(--transition), height var(--transition);
}

.Header_header__D4RXM[data-is-on-hero] {
  height: calc(var(--header-height) + 3rem);
  background-color: transparent;
  -webkit-backdrop-filter: none;
          backdrop-filter: none;
}

.Header_header__D4RXM[data-nav-toggled="true"] {
  height: 100vh;
  background-color: hsl(var(--background-color));
}

.Header_header__D4RXM[data-nav-toggled="true"] .Header_logo__UGbyD {
  display: none;
}

.Header_toggle-button__jfV4t[aria-expanded="true"] {
  border-color: transparent;
}

.Header_toggle-button__jfV4t[aria-expanded="true"] span:nth-of-type(2) {
  opacity: 0;
}

.Header_toggle-button__jfV4t[aria-expanded="true"] span:nth-of-type(1),
.Header_toggle-button__jfV4t[aria-expanded="true"] span:nth-of-type(3) {
  width: 100%;
}

.Header_toggle-button__jfV4t[aria-expanded="true"] span:nth-of-type(1) {
  transform: translateY(5px) rotate(45deg);
}

.Header_toggle-button__jfV4t[aria-expanded="true"] span:nth-of-type(3) {
  transform: translateY(-5px) rotate(-45deg);
}

.Header_nav__EgB8P {
  grid-column: -1/1;
  align-self: flex-start;
  justify-self: stretch;
  opacity: 0;
  pointer-events: none;
}

.Header_toggle-button__jfV4t[aria-expanded="true"] ~ .Header_nav__EgB8P {
  opacity: 1;
  pointer-events: all;
  transition: opacity var(--transition-duration-arrow) ease-in;
}

.Header_nav-links__T4AgU {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  list-style: none;
  padding: 0;
  padding-bottom: 2rem;
  margin: 0;
  font-size: 1.5rem;
  font-weight: bold;
  border-bottom: 1px solid hsl(var(--color-gray-200));
}

.Header_nav-link__PZaFB {
  transition: color var(--transition-duration-bullet) ease-in;
}

.Header_nav-links-contact-button__AifZ2 {
  width: 100%;
  margin-top: 1rem;
}

.Header_nav__EgB8P hr {
  border-top: 0;
  border-bottom: 1px solid hsl(var(--color-gray-200));
  margin: 0;
}

.Header_nav-contacts__AJQxQ {
  margin-block: 1.5rem;
}

.Header_nav-color-scheme-select__qJDmv {
  margin-block: 1.5rem;
}

.Header_logo__UGbyD {
  display: inline-grid;
  place-items: center;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  cursor: pointer;
}

.Header_logo-image__ApqkV {
  transition: height var(--transition), width var(--transition);
}

.Header_toggle-button__jfV4t {
  display: inline-grid;
  padding: 9px 6px;
  place-content: stretch;
  place-items: stretch;
  gap: 3px;
  width: 2rem;
  height: 2rem;
  border-radius: var(--br-max);
  background-color: transparent;
  border: 1px solid hsl(var(--color-gray-300));
  cursor: pointer;
}

.Header_toggle-button__jfV4t span {
  height: 2px;
  border-radius: var(--br-max);
  background-color: hsl(var(--foreground-color));
  transition: transform var(--transition-duration-arrow) ease-in-out,
    width var(--transition-duration-arrow) ease-in-out,
    opacity var(--transition-duration-arrow) ease-in-out;
}

.Header_toggle-button__jfV4t span:nth-of-type(2n + 1) {
  width: 80%;
  margin: 0 auto;
}

.Header_toggle-button__jfV4t span:nth-of-type(1) {
  grid-row: 1/2;
}

.Header_toggle-button__jfV4t span:nth-of-type(2) {
  grid-row: 2/3;
}

.Header_toggle-button__jfV4t span:nth-of-type(3) {
  grid-row: 3/4;
}

@media screen and (min-width: 48rem) {
  .Header_header__D4RXM {
    display: flex;
    justify-content: space-between;
    padding: 0 max(calc((100vw - var(--content-max-width)) / 2), 0.75rem);
    grid-template-columns: 0 1fr auto;
  }

  .Header_header__D4RXM[data-is-on-hero] .Header_logo-image__ApqkV {
    width: 182px;
    height: 72px;
  }

  .Header_toggle-button__jfV4t {
    display: none;
  }

  .Header_nav__EgB8P {
    opacity: 1;
    pointer-events: all;
    align-self: center;
    flex-direction: row;
    display: inline-flex;
    align-items: center;
    gap: 1em;
  }

  .Header_nav-links__T4AgU {
    font-size: 1rem;
    gap: 2rem;
    font-weight: normal;
    flex-direction: row;
    padding-bottom: 0;
    border-bottom: 0;
    align-items: center;
  }

  .Header_nav-links-contact-button__AifZ2 {
    margin: auto;
  }

  .Header_nav-links__T4AgU li {
    display: inline-block;
    white-space: nowrap;
    position: relative;
  }

  .Header_nav-links__T4AgU li::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: currentColor;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform ease-in var(--transition-duration-arrow);
  }

  .Header_nav-links__T4AgU li[data-current="true"] {
    color: hsl(var(--color-blue-500));
  }

  .Header_nav-links__T4AgU li[data-current="true"]::after {
    transform: scaleX(1);
  }

  .Header_nav-socials__3tOAU {
    display: none;
  }

  .Header_nav-contacts__AJQxQ {
    display: none;
  }

  .Header_nav-color-scheme-select__qJDmv {
    margin-block: 0;
  }

  .Header_nav__EgB8P hr {
    display: none;
  }
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.Toastify__toast-container {
  z-index: var(--toastify-z-index);
  -webkit-transform: translate3d(0, 0, var(--toastify-z-index));
  position: fixed;
  padding: 4px;
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  color: #fff;
}
.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}
.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}
.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}
.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}
.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}
.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (max-width : 480px) {
  .Toastify__toast-container {
    width: 100vw;
    padding: 0;
    left: 0;
    margin: 0;
  }
  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }
  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }
  .Toastify__toast-container--rtl {
    right: 0;
    left: initial;
  }
}
.Toastify__toast {
  position: relative;
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  max-height: var(--toastify-toast-max-height);
  overflow: hidden;
  font-family: var(--toastify-font-family);
  cursor: default;
  direction: ltr;
  /* webkit only issue #791 */
  z-index: 0;
}
.Toastify__toast--rtl {
  direction: rtl;
}
.Toastify__toast--close-on-click {
  cursor: pointer;
}
.Toastify__toast-body {
  margin: auto 0;
  flex: 1 1 auto;
  padding: 6px;
  display: flex;
  align-items: center;
}
.Toastify__toast-body > div:last-child {
  word-break: break-word;
  flex: 1 1;
}
.Toastify__toast-icon {
  -webkit-margin-end: 10px;
          margin-inline-end: 10px;
  width: 20px;
  flex-shrink: 0;
  display: flex;
}

.Toastify--animate {
  animation-fill-mode: both;
  animation-duration: 0.7s;
}

.Toastify--animate-icon {
  animation-fill-mode: both;
  animation-duration: 0.3s;
}

@media only screen and (max-width : 480px) {
  .Toastify__toast {
    margin-bottom: 0;
    border-radius: 0;
  }
}
.Toastify__toast-theme--dark {
  background: var(--toastify-color-dark);
  color: var(--toastify-text-color-dark);
}
.Toastify__toast-theme--light {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}
.Toastify__toast-theme--colored.Toastify__toast--default {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}
.Toastify__toast-theme--colored.Toastify__toast--info {
  color: var(--toastify-text-color-info);
  background: var(--toastify-color-info);
}
.Toastify__toast-theme--colored.Toastify__toast--success {
  color: var(--toastify-text-color-success);
  background: var(--toastify-color-success);
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: var(--toastify-text-color-warning);
  background: var(--toastify-color-warning);
}
.Toastify__toast-theme--colored.Toastify__toast--error {
  color: var(--toastify-text-color-error);
  background: var(--toastify-color-error);
}

.Toastify__progress-bar-theme--light {
  background: var(--toastify-color-progress-light);
}
.Toastify__progress-bar-theme--dark {
  background: var(--toastify-color-progress-dark);
}
.Toastify__progress-bar--info {
  background: var(--toastify-color-progress-info);
}
.Toastify__progress-bar--success {
  background: var(--toastify-color-progress-success);
}
.Toastify__progress-bar--warning {
  background: var(--toastify-color-progress-warning);
}
.Toastify__progress-bar--error {
  background: var(--toastify-color-progress-error);
}
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background: var(--toastify-color-transparent);
}

.Toastify__close-button {
  color: #fff;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease;
  align-self: flex-start;
}
.Toastify__close-button--light {
  color: #000;
  opacity: 0.3;
}
.Toastify__close-button > svg {
  fill: currentColor;
  height: 16px;
  width: 14px;
}
.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}
.Toastify__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  z-index: var(--toastify-z-index);
  opacity: 0.7;
  transform-origin: left;
}
.Toastify__progress-bar--animated {
  animation: Toastify__trackProgress linear 1 forwards;
}
.Toastify__progress-bar--controlled {
  transition: transform 0.2s;
}
.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: right;
}

.Toastify__spinner {
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border: 2px solid;
  border-radius: 100%;
  border-color: var(--toastify-spinner-color-empty-area);
  border-right-color: var(--toastify-spinner-color);
  animation: Toastify__spin 0.65s linear infinite;
}

@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    transform: translate3d(10px, 0, 0);
  }
  90% {
    transform: translate3d(-5px, 0, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}
@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }
  75% {
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    transform: translate3d(5px, 0, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}
@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  75% {
    transform: translate3d(0, 10px, 0);
  }
  90% {
    transform: translate3d(0, -5px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}
@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }
  75% {
    transform: translate3d(0, -10px, 0);
  }
  90% {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}
.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}
.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight;
}
.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}
.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft;
}
.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight;
}
.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}
.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    transform: perspective(400px);
  }
}
@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px);
  }
  30% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  from {
    transform: translate3d(110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInLeft {
  from {
    transform: translate3d(-110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInUp {
  from {
    transform: translate3d(0, 110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInDown {
  from {
    transform: translate3d(0, -110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}
@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}
@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}
@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}
.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}
.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}
.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}
.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
}
.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
}
.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
}
.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
}

@keyframes Toastify__spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*# sourceMappingURL=ReactToastify.css.map */
