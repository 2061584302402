.contacts {
  display: grid;
  gap: 1rem;
}

.contacts-title {
  font-weight: 700;
}

.contacts-address {
  display: grid;
  gap: 0.5rem;
  padding: 0;
  margin: 0;
}
