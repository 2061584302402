.header {
  --transition: var(--transition-duration-bullet) ease-in;

  display: grid;
  grid-template-columns: 2rem 1fr 2rem;
  grid-template-rows: var(--header-height) calc(100vh - var(--header-height));
  place-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  height: var(--header-height);
  padding-inline: 0.75rem;
  background-color: hsl(
    var(--background-color)
  ); /* 86% opacity for blur effect */
  /* backdrop-filter: blur(1.5rem); */
  z-index: 10;
  transition: background-color var(--transition), height var(--transition);
}

.header[data-is-on-hero] {
  height: calc(var(--header-height) + 3rem);
  background-color: transparent;
  backdrop-filter: none;
}

.header[data-nav-toggled="true"] {
  height: 100vh;
  background-color: hsl(var(--background-color));
}

.header[data-nav-toggled="true"] .logo {
  display: none;
}

.toggle-button[aria-expanded="true"] {
  border-color: transparent;
}

.toggle-button[aria-expanded="true"] span:nth-of-type(2) {
  opacity: 0;
}

.toggle-button[aria-expanded="true"] span:nth-of-type(1),
.toggle-button[aria-expanded="true"] span:nth-of-type(3) {
  width: 100%;
}

.toggle-button[aria-expanded="true"] span:nth-of-type(1) {
  transform: translateY(5px) rotate(45deg);
}

.toggle-button[aria-expanded="true"] span:nth-of-type(3) {
  transform: translateY(-5px) rotate(-45deg);
}

.nav {
  grid-column: -1/1;
  align-self: flex-start;
  justify-self: stretch;
  opacity: 0;
  pointer-events: none;
}

.toggle-button[aria-expanded="true"] ~ .nav {
  opacity: 1;
  pointer-events: all;
  transition: opacity var(--transition-duration-arrow) ease-in;
}

.nav-links {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  list-style: none;
  padding: 0;
  padding-bottom: 2rem;
  margin: 0;
  font-size: 1.5rem;
  font-weight: bold;
  border-bottom: 1px solid hsl(var(--color-gray-200));
}

.nav-link {
  transition: color var(--transition-duration-bullet) ease-in;
}

.nav-links-contact-button {
  width: 100%;
  margin-top: 1rem;
}

.nav hr {
  border-top: 0;
  border-bottom: 1px solid hsl(var(--color-gray-200));
  margin: 0;
}

.nav-contacts {
  margin-block: 1.5rem;
}

.nav-color-scheme-select {
  margin-block: 1.5rem;
}

.logo {
  display: inline-grid;
  place-items: center;
  user-select: none;
  cursor: pointer;
}

.logo-image {
  transition: height var(--transition), width var(--transition);
}

.toggle-button {
  display: inline-grid;
  padding: 9px 6px;
  place-content: stretch;
  place-items: stretch;
  gap: 3px;
  width: 2rem;
  height: 2rem;
  border-radius: var(--br-max);
  background-color: transparent;
  border: 1px solid hsl(var(--color-gray-300));
  cursor: pointer;
}

.toggle-button span {
  height: 2px;
  border-radius: var(--br-max);
  background-color: hsl(var(--foreground-color));
  transition: transform var(--transition-duration-arrow) ease-in-out,
    width var(--transition-duration-arrow) ease-in-out,
    opacity var(--transition-duration-arrow) ease-in-out;
}

.toggle-button span:nth-of-type(2n + 1) {
  width: 80%;
  margin: 0 auto;
}

.toggle-button span:nth-of-type(1) {
  grid-row: 1/2;
}

.toggle-button span:nth-of-type(2) {
  grid-row: 2/3;
}

.toggle-button span:nth-of-type(3) {
  grid-row: 3/4;
}

@media screen and (min-width: 48rem) {
  .header {
    display: flex;
    justify-content: space-between;
    padding: 0 max(calc((100vw - var(--content-max-width)) / 2), 0.75rem);
    grid-template-columns: 0 1fr auto;
  }

  .header[data-is-on-hero] .logo-image {
    width: 182px;
    height: 72px;
  }

  .toggle-button {
    display: none;
  }

  .nav {
    opacity: 1;
    pointer-events: all;
    align-self: center;
    flex-direction: row;
    display: inline-flex;
    align-items: center;
    gap: 1em;
  }

  .nav-links {
    font-size: 1rem;
    gap: 2rem;
    font-weight: normal;
    flex-direction: row;
    padding-bottom: 0;
    border-bottom: 0;
    align-items: center;
  }

  .nav-links-contact-button {
    margin: auto;
  }

  .nav-links li {
    display: inline-block;
    white-space: nowrap;
    position: relative;
  }

  .nav-links li::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: currentColor;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform ease-in var(--transition-duration-arrow);
  }

  .nav-links li[data-current="true"] {
    color: hsl(var(--color-blue-500));
  }

  .nav-links li[data-current="true"]::after {
    transform: scaleX(1);
  }

  .nav-socials {
    display: none;
  }

  .nav-contacts {
    display: none;
  }

  .nav-color-scheme-select {
    margin-block: 0;
  }

  .nav hr {
    display: none;
  }
}
